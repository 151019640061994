<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid gap-4 mt-3 w-full m-auto">
    <!-- <div class="w-full">
            <app-number-input [label]="'Records Number'" [placeholder]="'50'" [name]="'records-number'" [showButtons]="true"

            [control]="formGroup?.controls?.size"[type]="'number'" [maxFractionDigits]="0" [min]="1" [max]="totalElements"></app-number-input>
        </div>
        <div class="w-full">
            <app-drop-down-input
            [label]="'Range'"
            [control]="formGroup?.controls?.page"
            [items]="pageList"
            [name]="'dropdown'"
            [optionLabel]="'label'"
            [optionValue]="'value'"
            [multi]="false"
            [appendTo]="'body'"
            >
            </app-drop-down-input>
        </div>
        <div class="w-full">
            <app-drop-down-input
            [label]="'Export As'"
            [placeholder]="'Select Export Type'"
            [name]="'type-dropdown'"
            [optionLabel]="'name'"
            [items]="[
                { name: 'PDF', value: 'pdf' },
                { name: 'Excel', value: 'xls' },
                { name: 'CSV', value: 'csv' },
            ]"
            [optionValue]="'value'"
            [control]="formGroup?.controls?.type"
            [appendTo]="'body'"
            >
            </app-drop-down-input>
        </div> -->
    <div class="w-full">
      <app-basic-input
        label="Name"
        [placeholder]="'Enter Package Name'"
        [control]="formGroup?.controls?.name"
        [viewMode]="'create'"
      ></app-basic-input>
    </div>
    <div class="w-full">
      <app-drop-down-input
        [label]="'Types to Include'"
        [placeholder]="'Select Item'"
        [name]="'dropdown'"
        [optionLabel]="'label'"
        [optionValue]="'value'"
        [items]="listOfTypesToInclude"
        [control]="formGroup?.controls?.typesToInclude"
        [viewMode]="'create'"
        [badgeView]="true"
        [multi]="true"
        [loading]="isLoadingTypes"
      ></app-drop-down-input>
    </div>
    <div class="w-full">
      <app-target-code-selector
        [label]="'Selected Targets'"
        [placeholder]="'Search Targets...'"
        [control]="formGroup?.controls?.codesToExport"
        [viewMode]="fieldViewMode"
        [inlineMode]="false"
        [appendTo]="'body'"
        [multi]="true"
        [tableMode]="true"
        [optionLabel]="'name'"
        [optionValue]="'code'"
        [customProjectionFields]="['code', 'name', 'recordStatus']"
        [targetTypes]="selectedTypedToInclude"
      >
      </app-target-code-selector>
    </div>
    <div class="w-6">
      <app-switch-input
        [label]="'Export Manual Relations'"
        [name]="'exportManualRelations'"
        [control]="formGroup?.controls?.exportManualRelations"
        [viewMode]="fieldViewMode"
      >
      </app-switch-input>
    </div>
    <div class="w-6">
      <app-switch-input
        [label]="'Export Reference Related Items'"
        [name]="'exportReferenceRelations'"
        [control]="formGroup?.controls?.exportReferenceRelations"
        [viewMode]="fieldViewMode"
      >
      </app-switch-input>
    </div>
    <div class="w-full">
      <app-text-editor
        [height]="'115px'"
        [name]="'description'"
        label="Package Description"
        [placeholder]="'Enter Text...'"
        [control]="formGroup?.controls?.description"
        [viewMode]="'create'"
      ></app-text-editor>
    </div>
  </div>
  <div class="w-full">
    <app-button [action]="submitButtonAction"></app-button>
  </div>
</form>
