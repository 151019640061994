import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { BaseResponse, RequestHandlerOptions } from '@shared/classes';
import { Observable } from 'rxjs';
import { BaseRequestControllerService } from '../api/base-request-controller.service';
import { DataService } from '../api/data.service';

@Injectable({
  providedIn: 'root',
})
export class CommentsService extends BaseRequestControllerService<any> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(dataService, environment.relation, '/comments', ['id', 'code']);
  }

  apiOptions: RequestHandlerOptions = {
    showLoading: true,
    showMsg: true,
  };

  public getBySourceCode(sourceCode: string) {
    const newLocal = this.url + `/${sourceCode}`;
    return this.dataService.getData<BaseResponse<any>>(newLocal, this.apiOptions) as Observable<BaseResponse<any>>;
  }
}
