<div class="p-0">
  <!-- <div class="flex align-items-center justify-content-between mb-3">
  <p class="text-900 font-medium text-xl">Share <app-code-navigation [data]="itemId" ></app-code-navigation> </p>
</div> -->
  @if (!loading) {
    <div class="flex flex-column gap-3 w-full">
      <!-- <app-item-share-form>
  </app-item-share-form> -->
      <app-user-and-group-selector
        [label]="''"
        [placeholder]="'Users'"
        [control]="formControl"
        [optionValue]="undefined"
        [multi]="true"
        [viewMode]="fieldViewMode"
        (onChanges)="setUserListActions($event)"
        [extraExcludedSuggestions]="excludedItems"
        [floatLabel]="false"
        [itemTypes]="['GROUPS', 'RESPONSIBILITIES', 'USERS']"
      >
      </app-user-and-group-selector>
      @if (!(formControl?.value?.length > 0)) {
        <div class="flex flex-column w-full align-content-start gap-3">
          <h6 class="mb-0">Existing Access</h6>
          <!-- <ng-container *ngFor="let item of groupedSubscribers">
      <div *ngIf="item?.groupTitle" class="flex flex-column align-items-start gap-1">
        <div class="flex flex-row align-items-center gap-1">
          <i *ngIf="item?.icon" [class]="item?.icon"></i>
          <span>{{ item?.groupTitle | titlecase }}:</span>
        </div>
        <span class="text-500"> <i [class]="'pi pi-minus text-sm'"></i> {{ item?.type | titlecase }}</span>
      </div>
      <span *ngIf="!item?.groupTitle" [class]="item?.icon == 'pi pi-minus' ? 'text-500':''">
        <span> <i *ngIf="item?.icon" [class]="item?.icon + (item?.icon == 'pi pi-minus' ? ' text-sm' :'')"></i> {{ item?.type | titlecase }}</span>
      </span>
      <div *ngFor="let user of item.items" class="flex flex-row w-full justify-content-between align-items-center gap-3">
        <div class="flex flex-row align-items-center gap-3 w-full">
          <app-avatar-list [users]="user.principle"></app-avatar-list>
          <div class="flex flex-row align-items-center justify-content-between w-full">
            <div>
              <p class="line-clamp-1-ellipsis" style="max-width: 14rem;">{{user.principle}}</p>
            </div>
            <div class="flex flex-row gap-3 align-items-center">
              <small class="text-500">{{user.referenceName}}</small>
              <app-code-navigation style="max-width: 14rem;" [data]="user.referenceValue" ></app-code-navigation>
              <app-badge-view [data]="user.accessType"></app-badge-view>
              <div class="mb-0 flex flex-row gap-1 align-items-center">
                <small class="text-500">Granted By</small>
                <app-avatar-list [users]="user.creatorName"></app-avatar-list>
              </div>
              <div [pTooltip]="user?.creationDate | date : 'yyyy-MM-dd HH:mm:ss'" [tooltipPosition]="'top'" class="flex flex-row gap-1 align-items-end justify-content-end" style="min-width: 9rem;">
                <span class="text-500" timeago [date]="user?.creationDate | date : 'yyyy-MM-dd HH:mm:ss'" style="font-variant-numeric: lining-nums;"></span>
                <i class="text-500 text-lg pi pi-clock"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="w-6rem" style="min-width: 6rem;">
          <app-button-list [actionList]="user.actionList"
            [dropdownActionLabel]="user.accessLevel | titlecase"
            [dropdownActionColor]="'secondary'"
            [dropdownActionStyleClass]="'w-full text-left'"
          [listType]="'dropdown'"></app-button-list>
        </div>
      </div>
    </ng-container> -->
          <app-table
            [cols]="cols"
            [tableActionsListType]="'list'"
            [tableActions]="tableActions"
            [inputData]="subscribers"
            [offlineConfig]="offlineConfig"
            [passIndexWithRowDataToAction]="true"
            [sessionStorageKey]="undefined"
            [selectionMode]="null"
            class="w-full"
          >
            @if (fieldViewMode == 'edit') {
              <ng-template let-user #customActionTemplate>
                <div class="w-6rem" style="min-width: 6rem; justify-self: center">
                  <app-button-list
                    [actionList]="user.actionList"
                    [dropdownActionLabel]="user.accessLevel | titlecase"
                    [dropdownActionColor]="'secondary'"
                    [dropdownActionStyleClass]="'w-full text-left'"
                    [listType]="'dropdown'"
                  ></app-button-list>
                </div>
              </ng-template>
            }
          </app-table>
        </div>
      }
      @if (formControl?.value?.length > 0) {
        <div class="flex flex-column w-full align-content-start gap-1">
          @for (user of formControl?.value; track user) {
            <div class="flex flex-row w-full justify-content-between align-items-center">
              <div class="flex flex-row align-items-center gap-3">
                <app-avatar-list [users]="user?.name"></app-avatar-list>
                <!-- <p>{{ user?.name }}</p> -->
              </div>
              @if (fieldViewMode == 'edit') {
                <div class="w-6rem" style="min-width: 6rem">
                  <app-button-list
                    [actionList]="userActionsMap[user?.name]?.actions ?? []"
                    [dropdownActionLabel]="userActionsMap[user?.name]?.accessLevel | titlecase"
                    [dropdownActionColor]="'secondary'"
                    [dropdownActionStyleClass]="'w-full text-left'"
                    [listType]="'dropdown'"
                  ></app-button-list>
                </div>
              }
            </div>
          }
        </div>
      }
      <div class="flex w-full flex-column align-items-end">
        <app-button
          class="w-fit"
          [action]="subscribeAction"
          [customLabel]="formControl?.value?.length > 0 ? 'Add' : null"
          [customIcon]="formControl?.value?.length > 0 ? 'pi pi-user-plus' : null"
        ></app-button>
      </div>
    </div>
  }
  @if (loading) {
    <div class="">
      <ul class="m-0 p-0 list-none">
        <li class="mb-3">
          <div class="flex">
            <p-skeleton shape="circle" size="2rem" styleClass="mr-2"></p-skeleton>
            <p-skeleton shape="circle" size="2rem" styleClass="mr-2"></p-skeleton>
            <p-skeleton shape="circle" size="2rem" styleClass="mr-2"></p-skeleton>
            <p-skeleton shape="circle" size="2rem" styleClass="mr-2"></p-skeleton>
            <p-skeleton shape="circle" size="2rem" styleClass="mr-2"></p-skeleton>
            <div style="flex: 1">
              <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
              <p-skeleton width="75%"></p-skeleton>
            </div>
          </div>
        </li>
        <li class="">
          <div class="flex">
            <div style="flex: 1">
              <p-skeleton width="100%" height="3rem" styleClass=""></p-skeleton>
            </div>
          </div>
        </li>
      </ul>
    </div>
  }
</div>
